<template>
    <div id="Orders" class="m-0 p-0 row">

        <div class="col-12 d-flex py-5 pr-5 justify-content-md-end">
            <button @click.stop="orders_modal = true;"
                class="shadow_btn px-4 py-1 mr-3"
                type="button">
                Agregar Orden
            </button>
        </div>

        <div class="col-12">
            <ns-table :data="orders_table_data" 
                :allow_headers="orders_table_headers"
                color_headers="gray" color_headers_text="black" pagination_off
                color_body="white" border_body="white" color_body_text="black">
                <template v-slot:actions="data">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-12 position-relative">
                            <button @click.stop="data.item.open = true"
                                class="w-100"
                                style="border: none; background: none;"
                                type="button">
                                Ver
                            </button>

                            <div @mouseleave="data.item.open = false"
                                class="position-absolute d-flex flex-column bg-white" 
                                style="width: 200px; height: 140px; z-index: 15; top: -5vh; right: 0vw;" v-if="data.item.open">
                                <button @click.stop="selectTableItem('details', data.item.id)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Detalles
                                </button>

                                <button @click.stop="selectTableItem('edit', data.item.id)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Editar
                                </button>

                                <button @click.stop="selectTableItem('delete', data.item.id)"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Borrar
                                </button>
                            </div>

                        </div>
                    </div>
                </template>
            </ns-table>
        </div>

        <!----------- Modales  ------------->
        <ns-modal :show_modal="orders_modal" bg_color="white" :max_width="680"
            overlay_close @onClose="orders_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Productos" 
                        theme="light"
                        v-model="products"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                </div>
                
                <div class="col-12 col-md-6 pr-4 pl-4">
                   <ns-input label="Total Venta" 
                        theme="light"
                        v-model="total"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4 my-3">
                    <ns-input label="Datos de compra" 
                        theme="light"
                        v-model="sale_data"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                </div>

                <div class="col-6 mt-4 montserrat-bold">
                    <div class="row p-0 justify-content-end align-items-center h-100">
                        <div class="col-12 d-flex justify-content-between p-0 px-2">
                            <button
                                class="btn btn-success"
                                style="border-radius: 15px;"
                                type="button">
                                Guardar
                            </button>
                            <button @click.stop="clearFields"
                                class="btn btn-danger"
                                style="border-radius: 15px;"
                                type="button">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ns-modal>

        <ns-modal :show_modal="orders_details" bg_color="white" :max_width="980"
            overlay_close @onClose="orders_details = false">
            <div class="row w-100 justify-content-between p-0 m-0 text-dark">

                <div class="col-12 bg-light p-0 m-0 text-dark">
                    <p class="pl-2 py-1 m-0">
                        JRE2QLCE872EY1STSKG1
                    </p>
                </div>

                <div class="col-12 col-md-6 pt-2 px-0">
                    <img :src="map" class="img-fluid" />
                </div>

                <div class="col-12 col-md-6 pt-2 d-flex">
                    <div class="row">
                        <div class="col-12 col-md-6">

                            <strong>Fecha:</strong><br>
                            <span>
                                {{ new Date().toLocaleString()}}
                            </span>
                        </div>
                        <div class="col-12 col-md-6">
                            <strong>Levanto pedido:</strong><br>
                            <span>
                                Alejandra Pérez
                            </span>
                        </div>
                        <div class="col-12 col-md-6">
                            <strong>Customer ID:</strong><br>
                            <span>
                                00023102
                            </span>
                        </div>
                        <div class="col-12 col-md-6">
                            <strong>Télefono:</strong><br>
                            <span>
                                33-5556-3456
                            </span>
                        </div>
                        <div class="col-12 col-md-6">
                            <strong>Correo:</strong>
                            <span>
                                alejandrap@nomail.com
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-12 my-3">
                    <strong>Dirección:</strong> <br>
                    <span>
                        Av de los Astros, Valle de la Hacienda, Cuautitlan izcalli 54715
                    </span>
                </div>

                <div class="col-12 d-flex justify-content-end py-1">
                    <button class="btn btn-success"
                        type="button">
                        Aprobar
                    </button>
                    <button class="btn btn-primary ml-3"
                        type="button">
                        Ticket
                    </button>
                </div>

                <div class="col-12">
                    <hr style="border: 1px solid #1112">
                </div>

                <div class="col-12">
                    <h5>
                        Detalle de venta
                    </h5>
                </div>

                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <p>
                                <strong>
                                    Cantidad:
                                </strong>
                                <span>
                                    3
                                </span>
                            </p>
                        </div>

                        <div class="col-12 col-md-3">
                            <p>
                                <strong>
                                    Subtotal:
                                </strong>
                                <span>
                                    $1160.38
                                </span>
                            </p>
                        </div>

                        <div class="col-12 col-md-3">
                            <p>
                                <strong>
                                    Subtotal:
                                </strong>
                                <span>
                                    $1160.38
                                </span>
                            </p>
                        </div>

                        <div class="col-12 col-md-3">
                            <p>
                                <strong>
                                    Total:
                                </strong>
                                <span>
                                    $1174
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <hr style="border: 1px solid #1112">
                </div>

                <div class="col-12">
                    <h5>
                        Detalle del Pedido
                    </h5>
                </div>

                <div class="col-12">
                    <ns-table :data="product_data" 
                        :allow_headers="product_headers"
                        color_headers="gray" color_headers_text="black" pagination_off
                        color_body="white" border_body="white" color_body_text="black">  
                        <template v-slot:price="data">
                            $ {{ formatAmount(data.item.price)}}
                        </template>  
                        <template v-slot:total="data">
                            $ {{ formatAmount(data.item.total)}}
                        </template>  
                    </ns-table>
                </div>
                
            </div>
        </ns-modal>
    </div>
</template>

<script>
    import map from 'ASSETS/images/mapa_example.png'
    export default {
        data() {
            return {
                map,
                orders_modal: false,
                orders_details: false,
                orders_table_headers: [
                    {key: 'order_number', label: 'No. de Pedido'},
                    {key: 'products', label: 'Productos'},
                    {key: 'total', label: 'Total'},
                    {key: 'date', label: 'Fecha'},
                    {key: 'status', label: 'Estatus'},
                    {key: 'actions', label: 'Acciones'}
                ],
                orders_table_data: [
                    {id: 1, order_number: '02323422', products: 'item 1, item 2, item 3', total: '$1999', date: '03-mar-23',status: 'Publicado', actions: '-', open: false},
                ],
                product_data: [
                    { product: 'Kit Cand Clns', quantity: 3, price: 15, total: 45}
                ],
                product_headers: [
                    { key: 'product', label: 'Producto'},
                    { key: 'quantity', label: 'Cantidad'},
                    { key: 'price', label: 'Precio'},
                    { key: 'total', label: 'total'}
                ],
                products: null,
                sale_data: null,
                total: null,
            }
        },
        methods: {
            //helpers.
            clearFields() {
             
                this.products = null
                this.sale_data = null
                this.total = null

                this.orders_modal = false;
            },
            selectTableItem(type, id){
                //selecciona el id de un item, y despliega el modal necesario
                switch(type) {
                    case 'details':
                        this.orders_details = true;
                        break;
                    case 'edit':
                        console.log('id:', id)
                        break;
                    case 'delete':
                        console.log('id:', id)
                        break;
                    default:
                        console.info('Invalid case')
                        return
                }
            }
        },
        created() {
            try {
                //sets title for the view
                this.$store.commit('setTitle', 'ORDENES')
                this.$store.commit('setIndex', 4)
            }
            catch(error) {
                console.error(error)
            }
        }
    }
</script>

<style scoped>
    .custom_position {
        right: 10;
        
    }

    @media screen and (max-width: 928px){
            .custom_position {
                right: 0;
            }
        }
</style>